<template>
  <div><note-list /></div>
</template>

<script>
import NoteList from "./components/NoteList/NoteList.vue";
export default {
  components: { NoteList },
};
</script>

<style></style>
