<template>
  <b-modal
    id="edit-note-sidebar"
    centered
    :visible="isEditNoteSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    size="sm"
    backdrop
    no-header
    right
    :title="$t('updateNote')"
    :ok-title="$t('ok')"
    ok-only
    @ok="onSubmit(blankNoteData)"
    @hidden="formValidation(resetblanknote).resetForm"
    @change="(val) => changed(val)"
  >
    <validation-observer
      #default="{ handleSubmit }"
      ref="formValidation(
      resetblanknote
    ).refFormObserver"
    >
      <!-- Form -->

      <b-form
        ref="refForm"
        class="p-2 modal-form"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <button ref="submitButtonRef" type="submit" class="d-none" />
        <!-- NoteTopic -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="NoteTopic"
          class="w-100"
        >
          <b-form-group :label="$t('noteTopic')" label-for="NoteTopic">
            <b-form-input
              id="eNoteTopic"
              v-model="blankNoteData.noteTopic"
              autofocus
              :state="
                formValidation(resetblanknote).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- NoteDescription -->
        <validation-provider
          #default="validationContext"
          rules="required"
          name="NoteDescription"
          class="w-100"
        >
          <b-form-group
            :label="$t('noteDescription')"
            label-for="NoteDescription"
          >
            <b-form-input
              id="eNoteDescription"
              v-model="blankNoteData.noteDescription"
              autofocus
              :state="
                formValidation(resetblanknote).getValidationState(
                  validationContext
                )
              "
              trim
              placeholder=""
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <!-- ReminderDate -->
        <validation-provider
          #default="validationContext"
          name="ReminderDate"
          rules="required"
          class="w-100"
        >
          <b-form-group :label="$t('reminderDate')" label-for="ReminderDate">
            <b-form-datepicker
              v-model="blankNoteData.reminderDate"
              :locale="$i18n.locale"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <div class="w-100">
          <label for="patymentTermsE">{{ $t("remind") }}</label>
          <b-form-checkbox
            id="patymentTermsE"
            v-model="blankNoteData.remind"
            switch
          />
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    BFormCheckbox,
    BFormDatepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditNoteSidebarActive",
    event: "update:is-edit-note-sidebar-active",
  },
  props: {
    isEditNoteSidebarActive: {
      type: Boolean,
      required: true,
    },
    note: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankNoteData: { ...this.note },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
    };
  },
  mounted() {
    var { inputImageRenderer } = useInputImageRenderer(
      this.$refs.refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign
        this.picturechanged = true;
        this.$refs.previewEl.src = base64;
        this.imgStr = base64;
      }
    );
    this.inputImageRenderer = inputImageRenderer;
  },
  methods: {
    async changed(val) {
      this.imgStr = "";
      this.resetblanknote();
      this.$emit("update:is-edit-note-sidebar-active", val);
    },
    onSubmit() {
      this.blankNoteData.imgstr = this.imgStr;
      store
        .dispatch("definitionsModule/updateNote", this.blankNoteData)
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-note-sidebar-active", false);
        });
    },
    async resetblanknote() {
      this.blankNoteData = { ...this.note };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-note-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
